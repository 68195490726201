







































import { Component } from "vue-property-decorator";
import { TrainingSessionSaveLookups, TrainingSessionSaveModel } from "@/core/webapi";
import { ApiService } from "@/core/services";
import { ButtonCpt, TrainingSessionStatusChipCpt } from "@/core/components/common";
import AppVue from "@/AppVue.vue";
import moment from "moment";
import { TrainingSessionEditFormCpt, TrainingSessionAttendanceTableCpt, CancelTrainingSessionCpt } from "./components";

@Component({
  components: {
    ButtonCpt,
    TrainingSessionEditFormCpt,
    TrainingSessionStatusChipCpt,
    TrainingSessionAttendanceTableCpt,
    CancelTrainingSessionCpt,
  },
})
export default class TrainingSessionEditView extends AppVue {
  lookups = new TrainingSessionSaveLookups({
    academicYears: [],
    venues: [],
    trainingTypes: [],
  });

  model = new TrainingSessionSaveModel({
    capacity: 30,
    startDateTime: moment().local().add(1, "days").toISOString(),
    isCancelled: false,
  });

  get trainingSessionId() {
    return Number(this.$route.params.trainingSessionId) || 0;
  }

  async created() {
    await this.loadLookups();
    this.model.academicYearId = this.currentAcademicYear;
    if (this.trainingSessionId) {
      await this.loadModel();
    }
  }

  async loadModel() {
    this.isLoading = true;
    try {
      const response = await ApiService.training().getTrainingSessionSaveModel(this.trainingSessionId);
      this.model = response.data;
    } finally {
      this.isLoading = false;
    }
  }

  async loadLookups() {
    this.isLoading = true;
    try {
      const response = await ApiService.training().getTrainingSessionSaveLookups();
      this.lookups = response.data;
    } finally {
      this.isLoading = false;
    }
  }
}
