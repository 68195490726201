





























































import { Component } from "vue-property-decorator";
import { TrainingSessionModel, TrainingSessionSearchFilterModel, TrainingSessionSearchLookups } from "@/core/webapi";
import BaseGridComponent from "@/core/components/common/grid/BaseGridComponent.vue";
import { ApiService } from "@/core/services";
import { GridPagerCpt, GridSelectFilterCpt, GridSearchCpt } from "@/core/components/common/grid";
import { ButtonCpt, AcademicYearSelectCpt } from "@/core/components/common";
import { TrainingSessionSearchTableCpt } from "./components";

@Component({
  components: {
    TrainingSessionSearchTableCpt,
    GridPagerCpt,
    GridSelectFilterCpt,
    GridSearchCpt,
    ButtonCpt,
    AcademicYearSelectCpt,
  },
})
export default class TrainingSessionSearchView extends BaseGridComponent<TrainingSessionModel> {
  lookups = new TrainingSessionSearchLookups();

  hasGrid() {
    return !!this.grid;
  }

  async created() {
    await this.loadLookups();
    this.load();
  }

  async loadLookups() {
    this.isLoading = true;
    try {
      const response = await ApiService.training().getTrainingSessionSearchLookups();
      this.lookups = response.data;
    } finally {
      this.isLoading = false;
    }
  }

  getNewFilter() {
    const query = this.$route.query as any;
    // If academic year in URL, it must update the localStorage
    const academicYear = Number(query.academicYearId);
    if (academicYear && academicYear !== this.currentAcademicYear) {
      this.currentAcademicYear = academicYear;
    }

    return new TrainingSessionSearchFilterModel({
      pageSize: Number(query.pageSize) || 20,
      page: Number(query.page) || 1,
      academicYearId: this.currentAcademicYear,
      search: query.search || "",
      sortBy: query.sortBy || "StartDateTime",
      sortOrder: query.sortOrder || "asc",
    });
  }

  private load() {
    this.initialize(
      // eslint-disable-next-line
      filter => ApiService.training().filterTrainingSession(filter),
      "training-session-search",
      this.grid?.filter || this.getNewFilter(),
    );
  }
}
